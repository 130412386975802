@import "main-loading/main-loading.scss";

@font-face {
    font-family: "Roboto";
    src: url("assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "RobotoMono";
    src: url("assets/fonts/RobotoMono-Regular.ttf");
}

* {
    font-family: inherit;
    font-size: inherit;

    color: inherit;
}

html,
body {
    margin: 0;
    font-family: "Roboto";

    --color: black;
    --background-color: white;
    --button-hover: #F5A589;
    --button-color: #7D5546;
    --button-shade: #3D2922;

    background-color: var(--background-color);
}

.dark-mode {
    --color: white;
    --background-color: #333333;

    --button-hover: #7A53B5;
    --button-color: #54397D;
    --button-shade: #291C3D;
}

#app-main {
    display: flex;
    height: 100vh;
    width: 100%;

    color: var(--color);
    background-color: var(--background-color);

    justify-content: center;
    align-items: center;

    span {
        margin: 0 auto;
        display: inline-block;
    }

    .prompt {
        color: var(--color);
    }

    .subtle {
        color: var(--color);
    }

    .cozy-animation {
        width: 28em;
        height: 0.3em;
        margin: 0.5em auto;
    }

    .cozy-controls {
        padding: 0;
        overflow: visible;
    }

    .fx-now {
        position: relative;
        display: block;
        height: 1.3em;
        width: 100%;

        font-size: 4em;
        text-align: center;
        font-family: "RobotoMono";

        * {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0%;
            top: 0%;
        }
    }

    .cloak,
    .display-time,
    .display-text {
        opacity: 0;
        transition: opacity 1.2s linear;
    }

    &.mode-wait {

        .cloak,
        .cozy-controls,
        .display-time {
            opacity: 1;
        }
    }

    &.mode-live {

        .cloak,
        .display-text {
            opacity: 1;
        }

        .cozy-animation,
        .cozy-controls {
            opacity: 0;
        }
    }

    a {
        span {
            text-decoration: underline;
        }
    }

    a:hover span {
        color: #ee6055;
    }

    .stack {
        display: flex;
        row-gap: 5px;
        flex-direction: column;
    }

    #funny-button {
        width: 100%;
        cursor: pointer;
        margin-top: 1rem;
        font-family: "Roboto";
    }
}

.overflow-hidden {
    overflow: hidden;
}

.center {
    margin: 0 auto;
    text-align: center;
}

.flex-center {
    display: flex;

    align-items: center;
    justify-content: center;
}

button {
    border: 2px solid var(--button-shade);
    border-radius: 10px;

    box-shadow: 0px 4px 0px 0px var(--button-shade);

    transition: all 0ms;

    height: 64px;

    display: flex;

    align-items: center;
    justify-content: center;

    color: white;

    background-color: var(--button-color);

    font-family: Tahoma;
    font-size: 36px;

    text-decoration: none;

    &:hover {
        color: inherit;
        background-color: var(--button-hover);
    }

    &:active {
        top: 4px;
        position: relative;

        color: inherit;
        box-shadow: none;
    }
}

.overlay {
    display: grid;

    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    * {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }
}

.pointer-none {
    pointer-events: none;
}

.flyout {
    position: absolute;

    animation: flyout 1.8s;

    color: var(--color);
    font-size: 3em;

    text-shadow: var(--background-color) 0px 0px 10px;
}

/* Move it (define the animation) */
@keyframes flyout {
    from {
        opacity: 1;
        transform: translateY(-100px);
    }

    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

svg {
    fill: none;

    color: #3da658;
    stroke: #aaf683;
    stroke-width: 14;
    stroke-dasharray: 1 1.2;
    stroke-dashoffset: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    animation: test 4s infinite ease-in-out;
}

@keyframes test {
    from {
        stroke: #ee6055;
        stroke-dashoffset: 1.1;
    }

    40% {
        stroke: #60d394;
        stroke-dashoffset: 2.2;
    }

    60% {
        stroke: #ffd97d;
        stroke-dashoffset: 2.2;
    }

    100% {
        stroke: #ee6055;
        stroke-dashoffset: 3.3;
    }
}