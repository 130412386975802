.cozy-animation {
  background: linear-gradient(90deg, #ee6055, #60d394, #aaf683, #ffd97d, #ff9b85, #ee6055, #60d394) 0 0 / 600% 100%;
  animation: 2s linear infinite colorspin;
}

@keyframes colorspin {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.c5f94986.ttf");
}

@font-face {
  font-family: RobotoMono;
  src: url("RobotoMono-Regular.3463a75d.ttf");
}

* {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

html, body {
  --color: black;
  --background-color: white;
  --button-hover: #f5a589;
  --button-color: #7d5546;
  --button-shade: #3d2922;
  background-color: var(--background-color);
  margin: 0;
  font-family: Roboto;
}

.dark-mode {
  --color: white;
  --background-color: #333;
  --button-hover: #7a53b5;
  --button-color: #54397d;
  --button-shade: #291c3d;
}

#app-main {
  height: 100vh;
  width: 100%;
  color: var(--color);
  background-color: var(--background-color);
  justify-content: center;
  align-items: center;
  display: flex;
}

#app-main span {
  margin: 0 auto;
  display: inline-block;
}

#app-main .prompt, #app-main .subtle {
  color: var(--color);
}

#app-main .cozy-animation {
  width: 28em;
  height: .3em;
  margin: .5em auto;
}

#app-main .cozy-controls {
  padding: 0;
  overflow: visible;
}

#app-main .fx-now {
  height: 1.3em;
  width: 100%;
  text-align: center;
  font-family: RobotoMono;
  font-size: 4em;
  display: block;
  position: relative;
}

#app-main .fx-now * {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
}

#app-main .cloak, #app-main .display-time, #app-main .display-text {
  opacity: 0;
  transition: opacity 1.2s linear;
}

#app-main.mode-wait .cloak, #app-main.mode-wait .cozy-controls, #app-main.mode-wait .display-time, #app-main.mode-live .cloak, #app-main.mode-live .display-text {
  opacity: 1;
}

#app-main.mode-live .cozy-animation, #app-main.mode-live .cozy-controls {
  opacity: 0;
}

#app-main a span {
  text-decoration: underline;
}

#app-main a:hover span {
  color: #ee6055;
}

#app-main .stack {
  flex-direction: column;
  row-gap: 5px;
  display: flex;
}

#app-main #funny-button {
  width: 100%;
  cursor: pointer;
  margin-top: 1rem;
  font-family: Roboto;
}

.overflow-hidden {
  overflow: hidden;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

button {
  border: 2px solid var(--button-shade);
  box-shadow: 0px 4px 0px 0px var(--button-shade);
  height: 64px;
  color: #fff;
  background-color: var(--button-color);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-family: Tahoma;
  font-size: 36px;
  text-decoration: none;
  transition: all;
  display: flex;
}

button:hover {
  color: inherit;
  background-color: var(--button-hover);
}

button:active {
  color: inherit;
  box-shadow: none;
  position: relative;
  top: 4px;
}

.overlay {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

.overlay * {
  grid-area: 1 / 1 / 2 / 2;
}

.pointer-none {
  pointer-events: none;
}

.flyout {
  color: var(--color);
  text-shadow: var(--background-color) 0px 0px 10px;
  font-size: 3em;
  animation: 1.8s flyout;
  position: absolute;
}

@keyframes flyout {
  from {
    opacity: 1;
    transform: translateY(-100px);
  }

  100% {
    opacity: 0;
    transform: translateY(-400px);
  }
}

svg {
  fill: none;
  color: #3da658;
  stroke: #aaf683;
  stroke-width: 14px;
  stroke-dasharray: 1 1.2;
  stroke-dashoffset: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: 4s ease-in-out infinite test;
}

@keyframes test {
  from {
    stroke: #ee6055;
    stroke-dashoffset: 1.1px;
  }

  40% {
    stroke: #60d394;
    stroke-dashoffset: 2.2px;
  }

  60% {
    stroke: #ffd97d;
    stroke-dashoffset: 2.2px;
  }

  100% {
    stroke: #ee6055;
    stroke-dashoffset: 3.3px;
  }
}

/*# sourceMappingURL=index.aebc6286.css.map */
