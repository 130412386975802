.cozy-animation {
    background: linear-gradient(90deg, 
        #ee6055, 
        #60d394, 
        #aaf683,
        #ffd97d,
        #ff9b85,

        #ee6055,
        #60d394, 
    );
    
    background-size: 600% 100%;
    animation: 
        // squashstretch 1.4s ease-in-out 0s infinite alternate,
        colorspin 2s linear infinite;
        ;
}
@keyframes colorspin {
    0% {
        background-position: 0% 50%
    }
    100% {
        background-position: 100% 50%
    }
}